import React from 'react';
import ScrollNext from '../ScrollNext';
import NeonText from '../NeonText';

const MainSection = () => {
    return (
        <section
            id="home"
            className="relative flex flex-col items-center justify-center h-screen bg-black text-white"
            style={{
                backgroundColor: '#100D0E',
            }}
        >
            <div className="relative z-20 text-center">
                <h1 className="text-6xl md:text-8xl font-bold">Dmytro Kozin</h1>
                <p className="mt-4 text-lg md:text-2xl tracking-wide">
                    SOFTWARE ENGINEER
                </p>
                <p className="mt-2">
                    <a href="mailto:dimakoua@gmail.com"><NeonText text="open to work" /></a>
                </p>
            </div>

            <div className="absolute inset-0 z-1">
                <div
                    className="absolute inset-0 bg-gradient-to-b from-transparent to-[#100D0E]"
                    style={{
                        backgroundImage: `url('/fon.webp')`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        opacity: 0.6,
                    }}
                />
            </div>

            <ScrollNext to="expertise" />
        </section>
    );
};

export default MainSection;
