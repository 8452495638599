import KalipsoDetailsPage from "../pages/KalipsoDetailsPage";
import MainPage from "../pages/MainPage";
import SdufDetailsPage from "../pages/SdufDetailsPage";
import RootTemplate from "../RootTemplate";

const { createBrowserRouter } = require("react-router-dom");
const router = createBrowserRouter([
    {
        path: "/",
        element: <RootTemplate />,
        children: [
            {
                path: "/",
                element: <MainPage />,
            },
            {
                path: "/sduf",
                element: <SdufDetailsPage />,
            },
            {
                path: "/kalipso",
                element: <KalipsoDetailsPage />,
            }
        ]
    },

]);

export { router };