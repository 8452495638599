import React from 'react';

const NeonText = ({ text }) => {
    return (
        <div>
            <style>{`
                @-webkit-keyframes blinkH1 {
                    0%, 19%, 60%, 100% {
                        color: #fff;
                        text-shadow: 0px 0px 20px #00aad4;
                    }
                    20%, 61%, 63% {
                        color: #9E9E9E;
                        text-shadow: none;
                    }
                    64% {
                        color: #fff;
                        text-shadow: 0px 0px 20px #00aad4;
                    }
                }

                @-webkit-keyframes blinkH1After {
                    0%, 19%, 60%, 100% {
                        color: #00aad4;
                    }
                    20%, 61%, 63% {
                        color: transparent;
                    }
                }

                @-webkit-keyframes blinkH1Before {
                    0%, 19%, 60%, 100% {
                        background: #00aad4;
                    }
                    20%, 61%, 63% {
                        background: transparent;
                    }
                }

                .neon {
                    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
                    font-weight: 100;
                    margin: 0;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, -0%);
                    color: #fff;
                    font-size: 18px;
                    text-shadow: 0px 0px 20px #00aad4;
                    -webkit-animation: blinkH1 5s infinite;
                    animation: blinkH1 5s infinite;
                }

                .neon:after {
                    content: attr(data-text);
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: #00aad4;
                    z-index: -1;
                    filter: blur(15px);
                    -webkit-animation: blinkH1After 5s infinite;
                    animation: blinkH1After 5s infinite;
                }

                .neon:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: #00aad4;
                    z-index: -2;
                    opacity: 0.8;
                    filter: blur(50px);
                    -webkit-animation: blinkH1Before 5s infinite;
                    animation: blinkH1Before 5s infinite;
                }
            `}</style>
            <div className="neon" data-text={text}>
                {text}
            </div>
        </div>
    );
};

export default NeonText;
