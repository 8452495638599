import React, { useState } from 'react';
import Modal from './Modal';

const ScreensSection = () => {
    const [modalImage, setModalImage] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = (src, alt) => {
        setModalImage({ src, alt });
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalImage(null);
    };

    const screens = [
        {
            src: '/kalipso/dashboard.png',
            alt: 'Kalipso Dashboard',
            title: 'Dashboard Overview',
            description: 'A centralized view of affiliate marketing performance, including revenue, profit, and campaign performance insights. Quickly access top offers, publishers, and real-time notifications.',
        },
        {
            src: '/kalipso/magicButtonTab.svg',
            alt: 'Campaign & Partner Management',
            title: 'Campaign & Partner Management',
            description: 'Manage offers, track conversions, and oversee advertisers and affiliates. Assign account managers, approve publishers, and ensure campaigns run smoothly.',
        },
        {
            src: '/kalipso/filters.png',
            alt: 'Real-time Analytics',
            title: 'Real-time Analytics & Reports',
            description: 'Monitor performance metrics with advanced analytics. View earnings, conversions, and trends in real time to optimize marketing strategies.',
        }
    ];

    return (
        <div id="screens">
            {screens.map((screen, index) => (
                <section
                    key={index}
                    id={`screen-${index}`}
                    className="relative flex flex-col items-center justify-center min-h-screen bg-black text-white"
                    style={{ backgroundColor: '#100D0E' }}
                >
                    <div className="relative z-20 flex flex-col items-center px-6 md:px-12 lg:px-24 py-16 md:py-24 text-center max-w-4xl mx-auto">
                        {screen.images ? (
                            <div className="flex flex-col md:flex-row gap-8 md:gap-12 mb-8">
                                {screen.images.map((image, imgIndex) => (
                                    <div key={imgIndex} className="flex-1 cursor-pointer" onClick={() => openModal(image.src, image.alt)}>
                                        <img
                                            src={image.src}
                                            alt={image.alt}
                                            className="w-full h-auto max-w-xs md:max-w-md lg:max-w-lg rounded-lg shadow-2xl"
                                        />
                                        <h3 className="text-xl md:text-2xl font-semibold mt-4">{image.title}</h3>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <img
                                src={screen.src}
                                alt={screen.alt}
                                className="max-h-96 max-w-xs md:max-w-lg lg:max-w-3xl h-auto rounded-lg shadow-2xl mb-6 cursor-pointer"
                                onClick={() => openModal(screen.src, screen.alt)}
                            />
                        )}
                        <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold mb-4">{screen.title}</h2>
                        <p className="text-base md:text-lg lg:text-xl leading-relaxed text-gray-300">
                            {screen.description}
                        </p>
                    </div>
                </section>
            ))}
            <Modal
                isOpen={modalOpen}
                onClose={closeModal}
                src={modalImage?.src}
                alt={modalImage?.alt}
            />
        </div>
    );
};

export default ScreensSection;
