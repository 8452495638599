import React from 'react';
import { Link } from 'react-router-dom';

const FreelanceOpportunitiesSection = () => {
    return (
        <section id="contact" className="bg-black text-white py-16">
            <div className="container mx-auto flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8 p-5">
                {/* Left Side: Call to Action */}
                <div className="flex-1">
                    <h2 className="text-5xl font-bold mb-10">Contact</h2>
                    <p className="mb-36 text-2xl max-w-sm">
                        Please feel free to reach out using the contact form below, or connect with me through the provided contact details. I'll do my best to respond as quickly as possible.
                    </p>
                    <p className="text-2xl text-purple-500 underline mb-8"><a href="mailto:dimakoua@gmail.com">dimakoua@gmail.com</a></p>
                    <ul className="space-y-2">
                        <li><Link to={'https://www.linkedin.com/in/dmytro-kozin-tr/'}>LinkedIn<span className="ml-2">→</span></Link></li>
                        <li><Link to={'https://www.instagram.com/dmytrokozin/'}>Instagram<span className="ml-2">→</span></Link></li>
                        <li><Link to={'https://github.com/Dimakoua'}>Github<span className="ml-2">→</span></Link></li>
                        <li><Link to={'https://leetcode.com/dimakoua/'}>Leetcode<span className="ml-2">→</span></Link></li>
                    </ul>
                </div>

                {/* Right Side: Image */}
                <div className="flex-1 opacity-70 hidden md:block">
                    <img
                        src="/contact_me.webp"
                        alt="Contact Section Design"
                        className="w-full h-auto"
                    />
                </div>

            </div>
        </section>
    );
};

export default FreelanceOpportunitiesSection;
