import React from 'react';

const MyWorkSection = () => {
    const corporateProjects = [
        {
            title: "Kalipso",
            category: "Web Application",
            imageUrl: "/kalipso.png",
            description: "Powerful affiliate marketing software that enables businesses to manage, track, and optimize their performance marketing efforts.",
            link: "/kalipso"
        },
        {
            title: "Communa",
            category: "Web Application",
            imageUrl: "/communa.png",
            description: "User-friendly platform that allows users to earn gift cards from Amazon, Walmart, Visa, and more by participating in quick and easy surveys. The platform focuses on simplicity and engagement, enabling users to redeem rewards with minimal time investment.",
            link: "https://www.communa.com/"
        },
        {
            title: "123fahrschule",
            category: "Mobile Application",
            imageUrl: "/fahrschule.png",
            description: "A comprehensive online driving school platform in Germany, 123fahrschule offers users a modern and efficient way to prepare for their driving tests, with a user-friendly interface and extensive educational resources.",
            link: "https://www.123fahrschule.de/"
        },
        {
            title: "RIA Platforms",
            category: "Web Applications",
            imageUrl: "/auto_ria.png",
            description: "Platforms provide robust solutions for buying and selling cars, searching and listing real estate, and managing online payments. Each platform delivers a seamless and user-friendly experience, catering to the diverse needs of users.",
            link: "https://www.ria.com"
        }
    ];
    const personalProjects = [
        {
            title: "Server Driven UI Framework",
            category: "Mobile Application",
            imageUrl: "/sduf.png",
            description: "Dynamic Server Driven UI Framework, enabling real-time app customization similar to Airbnb's approach. Includes an intuitive admin panel that allows non-technical users to build and manage app components on the fly, reducing development time and enhancing flexibility",
            link: "/sduf",
            github: "https://github.com/Dimakoua/sdufReactNative"
        },
        {
            title: "Love Notes",
            category: "Mobile Application",
            imageUrl: "/love_notes.webp",
            description: "A thoughtful app designed to help couples stay connected by sending personalized digital love notes. With an intuitive interface and seamless notifications, it's the perfect tool for keeping the spark alive in any relationship.",
            link: "https://play.google.com/store/apps/details?id=com.kodimlovenotes",
            github: "https://github.com/Dimakoua/LoveNotes"
        },
        {
            title: "KwikCart",
            category: "Mobile Application",
            imageUrl: "/kwik_cart.webp",
            description: "KwikCart is a user-friendly shopping list app that simplifies your shopping experience. Easily create, manage, and track your lists, ensuring you stay organized and never miss an item. Simplify your shopping with KwikCart!",
            link: "https://play.google.com/store/apps/details?id=com.kwikcart",
            github: "https://github.com/Dimakoua/GroceryList"
        },
        {
            title: "Hare MQ",
            category: "Messaging Library",
            imageUrl: "/hare_mq.png",
            description: "Hare MQ is a high-performance messaging library built for modern distributed systems. With a lightweight design and robust features, it's ideal for developers looking to implement efficient message queuing in their applications.",
            link: "https://hex.pm/packages/hare_mq",
            github: "https://github.com/Dimakoua/hare_mq"
        },
    ];

    return (
        <section id="work" className="bg-black text-white min-h-screen py-16">
            {/* Title */}
            <div className="container mx-auto text-center mb-12">
                <h1 className="text-4xl md:text-5xl font-bold">My Work</h1>
                <p className="mt-4 text-lg md:text-2xl tracking-wide">
                    A collection of projects that showcase my expertise and dedication to building top-quality software.
                </p>
            </div>

            {/* Corporate Projects */}
            <div className="container mx-auto mb-12 p-5">
                <h2 className="text-3xl md:text-4xl font-bold mb-8">Corporate Projects</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    {corporateProjects.map((project, index) => (
                        <div key={index} className="relative bg-gray-800 rounded-lg overflow-hidden shadow-lg">
                            <img src={project.imageUrl} alt={project.title} className="w-full h-56 object-cover" />
                            <div className="p-6 pb-12">
                                <h3 className="text-xl font-bold mb-2">{project.title}</h3>
                                <p className="text-gray-400">{project.category}</p>
                                <p className="mt-4 text-gray-300">{project.description}</p>
                                <a
                                    href={project.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="absolute bottom-3 flex items-center mt-4"
                                >
                                    View Project <span className="ml-2">→</span>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* Personal Projects */}
            <div className="container mx-auto p-5">
                <h2 className="text-3xl md:text-4xl font-bold mb-8">Personal Projects</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    {personalProjects.map((project, index) => (
                        <div key={index} className="relative bg-gray-800 rounded-lg overflow-hidden shadow-lg">
                            <img src={project.imageUrl} alt={project.title} className="w-full h-56 object-cover" />
                            <div className="p-4 pb-20">
                                <h3 className="text-xl font-bold mb-2">{project.title}</h3>
                                <p className="text-gray-400">{project.category}</p>
                                <p className="mt-4 text-gray-300">{project.description}</p>
                                <a
                                    href={project.github}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="absolute bottom-12 flex items-center"
                                >
                                    GitHub
                                </a>
                                <a
                                    href={project.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="absolute bottom-3 flex items-center"
                                >
                                    View Project <span className="ml-2">→</span>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default MyWorkSection;