import React from 'react';
import MainSection from '../components/kalipso/MainSection';
import ScreensSection from '../components/kalipso/ScreensSection';
import Header from '../components/kalipso/Header';
import ScrollUpButton from '../components/ScrollUpButton';

const KalipsoDetailsPage = () => {
  return (
    <div>
      <Header />
      <MainSection />
      <ScreensSection />
      <ScrollUpButton />
    </div>
  );
};

export default KalipsoDetailsPage;
